'use client';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import React, { useEffect, useState } from 'react';
import { Header, Section } from 'react-aria-components';
import { MenuButtonItem, MenuItem } from './MenuItem';
import { UseMenuSearch } from './MenuSearch/useMenuSearch';
import { MenuSeparator, MenuSeparatorPlaceholder } from './MenuSeparator';
import { IMenuItem, MenuItems, MenuKey, MenuSelectionMode } from './types';
import { classNames } from '../../utils/classNames';
import { Tooltip } from '../Tooltip';
import { ShowMoreMenuItem } from './ShowMoreMenuItem';

export interface MenuSectionProps {
  size?: 'sm' | 'md' | 'lg';
  index: number;
  items: MenuItems;
  hidden?: boolean;
  hiddenKeys?: MenuKey[] | Set<MenuKey>;
  header?: React.ReactNode;
  first?: boolean;
  last?: boolean;
  truncate?: number;
  nextWithSeparator?: number;
  collapsible?: boolean;
  multiple?: boolean;
  expanded?: boolean;
  setExpanded: (ind: number) => void;
  selectionMode?: MenuSelectionMode;
  withCheckMark?: boolean;
  MenuSectionHeaderComponent?: React.FC<MenuSectionHeaderProps>;
  className?: string;
  sortFn?: UseMenuSearch<IMenuItem | '-'>['sort'];
}

export type MenuSectionHeaderProps = {
  group: React.ReactNode | string;
  headers?: Record<string, React.ReactNode>;
  actions?: React.ReactNode;
  expanded?: boolean;
  onExpanded?: (expanded: boolean) => void;
  itemsCount?: number;
  className?: string;
};

export const MenuSectionHeader: React.FC<MenuSectionHeaderProps> = (props) => {
  const { group, headers, itemsCount, actions, expanded, onExpanded, className, selectedItems } =
    props;

  const finalHeader =
    headers && typeof group === 'string' ? (
      headers[group]
    ) : (
      <div className={'flex items-center gap-x-2'}>
        {group}
        {!expanded && itemsCount > 0 ? (
          <div className={'flex justify-center items-center p-2'}>
            <span className={'text-gray-400 text-[10px] font-normal'}>{itemsCount}</span>
          </div>
        ) : null}
        {!expanded && selectedItems?.length ? (
          <div
            data-tooltip-id={`${group}-tooltip`}
            className={'w-1 h-1 bg-gray-200 flex justify-center items-center p-2 rounded-full'}
          >
            <span className={'text-black text-[10px] font-normal'}>{selectedItems.length}</span>
          </div>
        ) : null}
        {!expanded && selectedItems?.length ? (
          <Tooltip
            id={`${group}-tooltip`}
            positionStrategy={'fixed'}
            content={`${selectedItems.length} selected: ${selectedItems.map((item) => item.label).join(', ')}`}
          />
        ) : null}
      </div>
    );

  return finalHeader ? (
    <Header
      className={classNames(
        'group font-medium py-1 px-2 text-xs text-gray-500 tracking-wide sticky top-0 z-10 bg-gradient-to-t from-transparent via-white to-white via-[10%]',
        onExpanded || actions ? 'flex gap-2 justify-between' : '',
        onExpanded ? 'hover:text-gray-700 cursor-pointer' : '',
        expanded
          ? 'pb-1 text-gray-700 border-b border-px border-gray-100'
          : onExpanded
            ? 'pb-1 border-b border-px border-gray-100'
            : '',
        className,
        'leading-6'
      )}
      onClick={
        onExpanded
          ? () => {
              onExpanded(!expanded);
            }
          : undefined
      }
    >
      {finalHeader}
      <div className={'flex items-center gap-x-3'}>
        {onExpanded ? (
          <ChevronDownIcon
            className={classNames(
              'transition-all ease-in text-gray-400 group-hover:text-gray-500 bg-gray-100 bg-opacity-0 group-hover:bg-opacity-75 rounded w-4 h-4',
              expanded ? 'text-gray-700 -rotate-180' : ''
            )}
          />
        ) : null}
        {actions}
      </div>
    </Header>
  ) : null;
};

export const MenuSection: React.FC<MenuSectionProps> = (props) => {
  const {
    index,
    header,
    items,
    hiddenKeys = [],
    selectedKeys,
    selectionMode,
    withCheckMark = false,
    size,
    first,
    last,
    truncate,
    nextWithSeparator,
    className,
    multiple = false,
    collapsible,
    expanded,
    setExpanded,
    MenuSectionHeaderComponent = MenuSectionHeader,
    sortFn
  } = props;

  const isHidden = items.every(
    (item) =>
      item !== MenuSeparatorPlaceholder && Array.from(hiddenKeys).includes(item.id as MenuKey)
  );

  const [limit, setLimit] = useState(truncate);
  const totalLength = items.length;

  const hidden = totalLength - limit;
  const limitable = !hiddenKeys?.length && hidden > 1 && collapsible;
  const limited = limit > -1 && limitable;

  const visibleItems = items.slice(0, limited ? limit : Infinity).sort(sortFn);

  const selectedItems = selectedKeys
    ?.map((key) => items.find((item) => item.id === key))
    .filter(Boolean);

  const selectedHiddenItems = selectedKeys
    ?.filter((key) => visibleItems.every((item) => item.id !== key))
    .map((key) => items.find((item) => item.id === key))
    .filter(Boolean);

  useEffect(() => {
    if (collapsible && !expanded) {
      setLimit(truncate);
    }
  }, [collapsible, expanded]);

  return (
    <Section
      className={classNames(
        'flex flex-col',
        isHidden ? 'hidden' : 'mx-2',
        expanded ? (limitable ? 'pb-1' : 'pb-2') : '',
        className
      )}
      dependencies={[limit, hiddenKeys, items, limited]}
    >
      {limitable && !hiddenKeys?.length && !first && expanded ? <MenuSeparator /> : null}
      {header ? (
        <MenuSectionHeaderComponent
          group={header}
          // itemsCount={items.filter((item) => item !== MenuSeparatorPlaceholder).length}
          expanded={expanded}
          selectedItems={selectedItems}
          onExpanded={collapsible ? () => setExpanded(index) : undefined}
        />
      ) : null}
      {visibleItems.map((item, i) =>
        item === MenuSeparatorPlaceholder ? (
          <MenuSeparator key={i} />
        ) : item.onClick || item.to || item.href ? (
          <MenuButtonItem
            size={size}
            key={item.id}
            item={item}
            className={'mb-px'}
            hidden={
              (collapsible && !expanded) || Array.from(hiddenKeys).includes(item.id as MenuKey)
            }
          />
        ) : (
          <MenuItem
            key={item.id}
            item={item}
            size={size}
            className={'mb-px'}
            hidden={
              (collapsible && !expanded) || Array.from(hiddenKeys).includes(item.id as MenuKey)
            }
            selectionMode={selectionMode}
            withCheckMark={withCheckMark || multiple}
          />
        )
      )}
      {limitable && truncate > -1 && (!collapsible || expanded) ? (
        <ShowMoreMenuItem
          key={`${header}-more`}
          groupId={header}
          limit={limit}
          total={items.length}
          selectedItems={selectedHiddenItems}
          onClick={() => setLimit((prev) => (prev === truncate ? -1 : truncate))}
        />
      ) : null}
      {limitable && !nextWithSeparator && !last && expanded ? (
        <MenuSeparator className={'mt-1'} />
      ) : null}
    </Section>
  );
};
