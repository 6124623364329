import React, { createContext, useState } from 'react';
import { IChatState } from '#/packages/ai-copilot/types';
import { AdBreakdownModel } from '#/src/types/AdBreakdownModel';
import { AdCreative } from '#/src/types/AdCreative'; // Assuming this is the correct import for the ad object

interface ChatWithDataContextType {
  model: AdBreakdownModel | null;
  setModel: React.Dispatch<React.SetStateAction<AdBreakdownModel | null>>;
  templates: Record<string, string>;
  setTemplates: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  ad: AdCreative | null;
  setAd: React.Dispatch<React.SetStateAction<AdCreative | null>>;
  adChatState: IChatState;
  setAdChatState: React.Dispatch<React.SetStateAction<IChatState>>;
  reportChatState: IChatState;
  setReportChatState: React.Dispatch<React.SetStateAction<IChatState>>;
}

export const ChatWithDataContext = createContext<ChatWithDataContextType | undefined>(undefined);

export const ChatWithDataProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [model, setModel] = useState<AdBreakdownModel | null>(null);
  const [templates, setTemplates] = useState<Record<string, string> | null>(null);
  const [ad, setAd] = useState<AdCreative | null>(null);
  const [adChatState, setAdChatState] = useState<IChatState>({
    status: null,
    adgen_urls: [],
    selected_adgen_url_index: -1
  });
  const [reportChatState, setReportChatState] = useState<IChatState>({ status: null });

  return (
    <ChatWithDataContext.Provider
      value={{
        model,
        setModel,
        templates,
        setTemplates,
        ad,
        setAd,
        adChatState,
        setAdChatState,
        reportChatState,
        setReportChatState
      }}
    >
      {children}
    </ChatWithDataContext.Provider>
  );
};
