'use client';
import { useCallback, useMemo, useState } from 'react';
import { Key } from 'react-aria-components';
import { IMenuItem, IMenuItemsGroup, MenuKey } from '../Menu';
import { UseMenuSearch, useMenuSearch } from '../Menu/MenuSearch/useMenuSearch';

export const useDropdownMenu = (props: {
  value: IMenuItem['id'] | IMenuItem['id'][];
  open?: boolean;
  options: IMenuItem[];
  groups?: IMenuItemsGroup[];
  onChange: (value: Key[]) => void;
  sort?: UseMenuSearch<IMenuItem>['sort'];
}) => {
  const {
    value: initialValue,
    options,
    groups: initialGroups,
    open: initialOpen = false,
    onChange
  } = props;

  const [open, setOpen] = useState(initialOpen);

  const {
    filter,
    filteredKeys: hiddenKeys,
    onFilterChange,
    sort
  } = useMenuSearch<IMenuItem>(options);

  const groups = useMemo<IMenuItemsGroup[]>(() => {
    const grouped =
      initialGroups ||
      options.reduce(
        (groups, { group, ...item }) => ({
          ...groups,
          [group || '']: { items: [...((group && groups[group]?.items) || []), item] }
        }),
        {} as Record<string, { items: IMenuItem[] }>
      );

    return Object.keys(grouped).map((header) => ({
      header,
      items: grouped[header].items,
      collapsible: options.length > 8,
      expanded: true
    }));
  }, [options, initialGroups]);

  const selectedKeys = useMemo<MenuKey[]>(
    () =>
      (Array.isArray(initialValue) ? initialValue : [initialValue]).filter(Boolean) as MenuKey[],
    [initialValue]
  );
  const disabledKeys = useMemo<MenuKey[]>(
    () => options.filter((it) => it.disabled).map((it) => it.id as MenuKey),
    [options]
  );
  const selectedProperties = useMemo(
    () => options?.filter((it) => it.id && selectedKeys.includes(it.id)),
    [options, selectedKeys]
  );

  const value = selectedKeys;
  const setValue = onChange;
  const [multiple, setMultiple] = useState<boolean>(selectedProperties?.length > 1);

  const onOpenChange = useCallback(
    (isOpen: boolean) => {
      onFilterChange('');
      setOpen(isOpen);
      if (isOpen) {
        setMultiple(selectedProperties?.length > 1);
      }
    },
    [onFilterChange, selectedProperties?.length]
  );

  return {
    groups,
    value,
    setValue,
    open,
    setOpen,
    multiple,
    setMultiple,
    filter,
    onFilterChange,
    hiddenKeys,
    disabledKeys,
    selectedKeys,
    selectedProperties,
    onOpenChange,
    sort
  };
};
