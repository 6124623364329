'use client';
import React, { LegacyRef, useLayoutEffect, useMemo, useRef, useState } from 'react';
import type { Selection } from 'react-aria-components';
import { Menu as BaseMenu, MenuProps as BaseMenuProps, Separator } from 'react-aria-components';
import { UseMenuSearch } from './MenuSearch/useMenuSearch';
import { MenuSection, MenuSectionProps } from './MenuSection';
import { MenuSeparator, MenuSeparatorPlaceholder } from './MenuSeparator';
import { IMenuItem, IMenuItemsGroup, MenuItems, MenuKey, MenuSelectionMode } from './types';
import { buildMenuItemGroups } from './utils';
import { classNames } from '../../utils/classNames';

export interface MenuProps
  extends Pick<MenuSectionProps, 'withCheckMark' | 'MenuSectionHeaderComponent'> {
  size?: 'sm' | 'md' | 'lg';
  items?: MenuItems;
  groups?: IMenuItemsGroup[];
  selectionMode?: MenuSelectionMode | 'group-multiple';
  collapseMode?: 'single' | 'multiple';
  selectedKeys?: MenuKey[] | Set<MenuKey>;
  disabledKeys?: MenuKey[] | Set<MenuKey>;
  hiddenKeys?: MenuKey[] | Set<MenuKey>;
  autoFocus?: boolean;
  className?: string;
  truncate?: number;
  emptyList?: React.ReactNode;
  children?: React.ReactNode;
  onAction?: (key: MenuKey | MenuKey[]) => void;
  onSelectionChange?: (keys: Selection) => void;
  sectionClassName?: string;
  dependencies?: BaseMenuProps<any>['dependencies'];
  MenuSectionComponent?: React.FC<MenuSectionProps>;
  'aria-label'?: string;
  sortFn?: UseMenuSearch<any>['sort'];
}

export const Menu: React.FC<MenuProps> = (props) => {
  const {
    size,
    items = [],
    truncate = 7,
    groups: initialGroups,
    children,
    hiddenKeys,
    emptyList = 'No items',
    withCheckMark = false,
    sectionClassName,
    MenuSectionComponent = MenuSection,
    MenuSectionHeaderComponent,
    collapseMode = 'single',
    dependencies,
    sortFn,
    ...rest
  } = props;

  const groups: IMenuItemsGroup[] = useMemo(() => {
    const groups = initialGroups || buildMenuItemGroups(items);

    return groups.map((group) => {
      if (group === MenuSeparatorPlaceholder) {
        return group;
      }

      return {
        ...group,
        hidden:
          group.hidden ||
          group.items.every(
            (item) =>
              item !== MenuSeparatorPlaceholder &&
              Array.from(hiddenKeys || []).includes(item.id as MenuKey)
          )
      };
    });
  }, [items, hiddenKeys, initialGroups]);

  const allHidden = groups.every((group) => group === MenuSeparatorPlaceholder || group.hidden);
  const firstNonHiddenGroupIndex = groups.findIndex(
    (group) => group !== MenuSeparatorPlaceholder && !group.hidden
  );
  const lastNonHiddenGroupIndex = groups.findLastIndex(
    (group) => group !== MenuSeparatorPlaceholder && !group.hidden
  );

  const [expanded, setExpanded] = useState<number[]>(() =>
    collapseMode === 'single'
      ? [groups.findIndex((group) => group !== MenuSeparatorPlaceholder)]
      : groups.map((group, i) => (group !== MenuSeparatorPlaceholder && group.expanded ? i : -1))
  );

  const ref = useRef<HTMLDivElement>();
  const expandedRef = useRef(-1);
  useLayoutEffect(() => {
    if (expanded !== null && expandedRef.current >= 0) {
      setTimeout(() => {
        if (expandedRef.current === 0) {
          return;
        }
        const top = new Array(expandedRef.current).fill('').reduce((acc, val, i) => {
          return acc + ref.current?.querySelector(`section:nth-child(${i + 1})`)?.clientHeight || 0;
        }, 36);

        expandedRef.current = -1;
        (ref.current?.parentNode as HTMLDivElement)?.scrollTo?.({
          top,
          left: 0
        });
      }, 10);
    }
  }, [expanded, ref.current]);

  return (
    <>
      {allHidden ? (
        typeof emptyList === 'string' ? (
          <div className={'px-4 py-2'}>
            <span className={'text-gray-500 text-sm'}>{emptyList}</span>
          </div>
        ) : (
          emptyList
        )
      ) : null}
      <BaseMenu
        ref={ref as LegacyRef<HTMLDivElement> | undefined}
        aria-label='menu'
        {...rest}
        dependencies={dependencies}
        selectionMode={rest.selectionMode === 'group-multiple' ? 'multiple' : rest.selectionMode}
      >
        {children || (
          <>
            {groups.map((group, i) => {
              const sectionExpanded = Boolean(
                expanded.includes(i) || [...(hiddenKeys || [])].length
              );
              const setSectionExpanded = (index: number) => {
                expandedRef.current = index;
                setExpanded((prev) =>
                  collapseMode === 'single'
                    ? prev.includes(index)
                      ? prev.filter((p) => p !== index)
                      : [index]
                    : index > -1 && !prev.includes(index)
                      ? [...prev, index]
                      : prev.filter((p) => p !== index)
                );
              };

              const nextGroup = groups[i + 1];
              const nextWithSeparator = nextGroup
                ? nextGroup === MenuSeparatorPlaceholder ||
                  shouldGroupHasSeparator(nextGroup, i + 1) ||
                  nextGroup.className?.includes('border-t')
                : false;

              const sectionSelectedKeys = Array.from(rest.selectedKeys || [])?.filter((key) =>
                group.items.some((item) => item.id === key)
              );

              return group === MenuSeparatorPlaceholder ? (
                <MenuSeparator key={i} />
              ) : (
                <React.Fragment key={i}>
                  {shouldGroupHasSeparator(group, i) ? <MenuSeparator /> : null}
                  <MenuSectionComponent
                    size={size}
                    index={i}
                    first={i === firstNonHiddenGroupIndex}
                    last={i === lastNonHiddenGroupIndex}
                    truncate={truncate}
                    nextWithSeparator={nextWithSeparator}
                    items={group.items}
                    header={group.header}
                    multiple={group.multiple}
                    collapsible={group.collapsible}
                    hiddenKeys={hiddenKeys}
                    selectedKeys={sectionSelectedKeys}
                    selectionMode={rest.selectionMode}
                    withCheckMark={withCheckMark}
                    MenuSectionHeaderComponent={MenuSectionHeaderComponent}
                    expanded={sectionExpanded}
                    setExpanded={setSectionExpanded}
                    className={classNames(
                      sectionClassName,
                      group.className,
                      i === firstNonHiddenGroupIndex ? 'pt-2' : '',
                      i === lastNonHiddenGroupIndex ? (group.collapsible ? 'pb-3' : 'pb-2') : ''
                    )}
                    sortFn={sortFn}
                  />
                </React.Fragment>
              );
            })}
          </>
        )}
      </BaseMenu>
    </>
  );
};

const shouldGroupHasSeparator = (group, i) =>
  i !== 0 && !group.header && !group.hidden && group.separator !== null;
